import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Config from "../../values/Strings/Config";
import { connect } from "react-redux";
import { ActionCreators } from "../../actions";
import moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAlert } from "react-alert";
import { findLastRow } from "../../values/utils";
import arrowLeft2 from "../../assets/images/arrow-left2.svg";
import arrowRight2 from "../../assets/images/arrow-right2.svg";
import footerLogo from "../../assets/images/footer-logo.svg";
import twitterIcon from "../../assets/images/twitter-icon.svg";
import instagramIcon from "../../assets/images/instagram-icon.svg";
import facebookIcon from "../../assets/images/facebook-icon.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Footer({ subjectDSPCH, subjectData, userData }) {
  const alert = useAlert();
  const slider = useRef();
  const [newsletterLoading, setNewsLetterLoading] = useState("");

  useEffect(() => {
    getSubject();
  }, []);

  const getSubject = () => {
    subjectDSPCH();
  };

  var settingFooter = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    infinite: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 5000,
        settings: "unslick",
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,

          autoplay: false,
          autoplaySpeed: 2000,
          speed: 500,
          infinite: true,
          centerMode: false,
        },
      },
    ],
  };

  const onSubmit = (val) => {
    let auth_obj = {
      fullname: userData?.data?.user?.name,
      email: val?.email,
      subscription_type: val?.type,
    };
    let without_auth_obj = {
      email: val.email,
      subscription_type: val.type,
    };
    setNewsLetterLoading(true);
    let final_obj = userData?.success === true ? auth_obj : without_auth_obj;
    fetch(Config.NEWSLETTER_SUBSCRIPTION_URL, {
      method: "POST",
      body: JSON.stringify(final_obj),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        setNewsLetterLoading(false);
        if (res?.success === true) {
          alert.success(
            <div style={{ textTransform: "initial" }}>{res?.data?.message}</div>
          );
        }
      })
      .catch((err) => {
        setNewsLetterLoading(false);
        alert.success(
          <div style={{ textTransform: "initial" }}>{err?.message}</div>
        );
      });
  };

  return (
    <div>
      {/* <!--============================== Footer Start ==============================--> */}
      <div className="content-container cta-container d-none d-md-block p-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Formik
                initialValues={{
                  email: "",
                  type: "weekly",
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email("Email is invalid")
                    .required("Email is required"),
                })}
                onSubmit={(fields, { resetForm }) => {
                  resetForm();
                  onSubmit(fields);
                }}
              >
                {({
                  errors,
                  touched,
                  setFieldValue,
                  values,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="cta-content text-center d-flex flex-wrap">
                      <div className="cta-left">
                        <h3>Quality Reporting. Informed Opinion.</h3>
                      </div>
                      <div className="cta-mid">
                        <div className="form-group form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="reporting"
                            value="weekly"
                            id="weekly1"
                            checked={values.type === "weekly"}
                            onChange={() => setFieldValue("type", "weekly")}
                          />
                          <label className="form-check-label" for="weekly1">
                            Weekly
                          </label>
                        </div>
                        <div className="form-group form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="reporting"
                            value="monthly"
                            id="monthly1"
                            checked={values.type === "monthly"}
                            onChange={() => setFieldValue("type", "monthly")}
                          />
                          <label className="form-check-label" for="monthly1">
                            Monthly
                          </label>
                        </div>
                      </div>
                      <div className="cta-right">
                        <div
                          className="form-group"
                          style={{ backgroundColor: "white" }}
                        >
                          <input
                            type="email"
                            id="email"
                            placeholder="Email Address"
                            className="form-control"
                            value={values.email}
                            onChange={(e) =>
                              setFieldValue("email", e.target.value)
                            }
                          />
                        </div>
                        {touched.email && errors.email && (
                          <div
                            style={{
                              textAlign: "left",
                              color: "red",
                              marginBottom: 10,
                              fontSize: 12,
                              backgroundColor: "transparent",
                            }}
                          >
                            {errors.email}
                          </div>
                        )}
                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn btn-default btn-bloack btn-sm "
                            disabled={isSubmitting}
                          >
                            {newsletterLoading ? "Loading..." : "SIGN UP"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {/* <!--============================== Content End ==============================--> */}
      {/* <!--============================== Content Start ==============================--> */}
      <div className="content-container most-interest-container more-pad">
        <div className="container">
          <div className="row g-0">
            <div className="col-lg-12">
              <div className="most-interest-heading">
                <h3 className="d-none d-md-block">
                  Browse Via Topics That <br /> Interest You The Most
                </h3>
                <h3 className="d-md-none">Browse Via Topics</h3>
              </div>
              <Slider
                {...settingFooter}
                ref={(c) => (slider.current = c)}
                className="most-interest-list most-interest-slider d-md-flex flex-wrap pb-0"
              >
                {subjectData?.success === true &&
                  subjectData?.data?.map((item, index) => (
                    <div
                      className={
                        "most-interest-item" +
                        (index > findLastRow(subjectData?.data?.length)
                          ? " no-line"
                          : "")
                      }
                      key={index}
                    >
                      <Link
                        to={`/subject/` + item?.slug}
                        className={`most-interest-link`}
                      >
                        <div className="most-interest-icon">
                          <img
                            src={Config.SUBJECT_IMG_URL + item?.image}
                            alt=""
                          />
                        </div>
                        <span>{item?.title}</span>
                      </Link>
                    </div>
                  ))}
              </Slider>

              <div className="custom-arrow d-md-none">
                <a
                  onClick={() => slider.current.slickPrev()}
                  className="custom-arrow-btn"
                  id="meLeft"
                >
                  <img src={arrowLeft2} alt="" />
                </a>
                <span>SWIPE TO BROWSE</span>
                <a
                  onClick={() => slider.current.slickNext()}
                  className="custom-arrow-btn"
                  id="meRight"
                >
                  <img src={arrowRight2} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--============================== Content End ==============================--> */}
      {/* <!--============================== Content Start ==============================--> */}
      <div className="content-container cta-container d-md-none">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Formik
                initialValues={{
                  email: "",
                  type: "weekly",
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email("Email is invalid")
                    .required("Email is required"),
                })}
                onSubmit={(fields) => onSubmit(fields)}
              >
                {({
                  errors,
                  touched,
                  setFieldValue,
                  values,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="cta-content text-center d-flex flex-wrap">
                      <div className="cta-left">
                        <h3>Quality Reporting. Informed Opinion.</h3>
                      </div>
                      <div className="cta-mid">
                        <div className="form-group form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="reporting"
                            value="weekly"
                            id="weekly2"
                            checked={values.type === "weekly"}
                            onChange={() => setFieldValue("type", "weekly")}
                          />
                          <label className="form-check-label" htmlFor="weekly2">
                            Weekly
                          </label>
                        </div>
                        <div className="form-group form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="reporting"
                            value="monthly"
                            id="monthly2"
                            checked={values.type === "monthly"}
                            onChange={() => setFieldValue("type", "monthly")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="monthly2"
                          >
                            Monthly
                          </label>
                        </div>
                      </div>
                      <div className="cta-right">
                        <div
                          className="form-group "
                          style={{ backgroundColor: "white" }}
                        >
                          <input
                            type="email"
                            placeholder="Email Address"
                            className="form-control "
                            value={values.email}
                            onChange={(e) =>
                              setFieldValue("email", e.target.value)
                            }
                          />
                        </div>
                        {touched.email && errors.email && (
                          <div
                            style={{
                              textAlign: "left",
                              color: "red",
                              marginBottom: 10,
                              fontSize: 12,
                              backgroundColor: "transparent",
                            }}
                          >
                            {errors.email}
                          </div>
                        )}
                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn btn-default btn-bloack btn-sm "
                            disabled={isSubmitting || newsletterLoading}
                          >
                            {newsletterLoading ? "Loading..." : "SIGN UP"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <footer id="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="footer-content d-flex flex-wrap">
                <div className="footer-left">
                  <Link to="/" className="footer-logo">
                    <img src={footerLogo} alt="" />
                  </Link>
                </div>
                <div className="footer-mid d-flex flex-wrap">
                  <ul className="footer-nav-links">
                    <li>
                      <Link to="/issue-dispatch" className="footer-nav-link">
                        Issue Dispatch
                      </Link>
                    </li>
                    <li>
                      <Link to="/about-us" className="footer-nav-link">
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact-us" className="footer-nav-link">
                        Contact
                      </Link>
                    </li>
                    <li>
                      <Link to="/archives" className="footer-nav-link">
                        Archives
                      </Link>
                    </li>
                  </ul>
                  <ul className="footer-nav-links">
                    <li>
                      <Link to="/privacy-policy" className="footer-nav-link">
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/terms-and-conditions"
                        className="footer-nav-link"
                      >
                        Terms and Conditions
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/refund-and-cancellations"
                        className="footer-nav-link"
                      >
                        Refunds and Cancellations
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/delivery-and-shipping"
                        className="footer-nav-link"
                      >
                        Delivery and Shipping
                      </Link>
                    </li>
                    <li>
                      <Link to="/faq" className="footer-nav-link">
                        FAQ
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="footer-right">
                  <ul className="footer-social-links d-flex">
                    <li>
                      <a
                        href="https://twitter.com/Journeyofobject"
                        className="footer-social-link"
                        target={"_blank"}
                      >
                        <img src={twitterIcon} style={{ width: 21 }} alt="" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/journeyofobjects/"
                        className="footer-social-link"
                        target={"_blank"}
                      >
                        <img src={instagramIcon} style={{ width: 19 }} alt="" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/people/Journey-of-Objects/100088542096391/"
                        className="footer-social-link"
                        target={"_blank"}
                      >
                        <img src={facebookIcon} style={{ width: 11 }} alt="" />
                      </a>
                    </li>
                  </ul>
                  <p className="copyright">
                    All Rights Reserved {moment().year()}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!--============================== Footer End ==============================--> */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  subjectData: state.getSubject.data,
  userData: state.login.user,
});

const mapDispatchToProps = (dispatch) => ({
  subjectDSPCH: () => {
    dispatch(ActionCreators.getSubject());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
