import moment from "moment";
import { isMobile } from "react-device-detect";

const isValidUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
};

const wordsCutting = (yourString, maxLength) => {
  if (yourString.length < maxLength) {
    return yourString;
  } else {
    var trimmedString = yourString.substr(0, maxLength);
    trimmedString = trimmedString.substr(
      0,
      Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
    );
    return trimmedString + "...";
  }
};

const convertDateFormate = (date) => {
  return moment(date).format("MMM D, YYYY");
};

const findLastRow = (length) => {
  let findDevidedNum = 0;
  let dividedby = isMobile ? 2 : 3;
  for (let i = 1; i < length; i++) {
    if (i % dividedby === 0) {
      findDevidedNum = i;
    }
  }
  return findDevidedNum - 1;
};

const fixedHeader = () => {
  // Header fixed on scroll.
  window.onscroll = function () {
    myFunction();
  };
  var body = document.getElementsByTagName("body")[0];
  var sticky = body.offsetTop + 290;

  function myFunction() {
    if (window.pageYOffset > sticky) {
      body.classList.add("fixed-haeder");
    } else {
      body.classList.remove("fixed-haeder");
    }
  }
};

export {
  isValidUrl,
  convertDateFormate,
  wordsCutting,
  findLastRow,
  fixedHeader,
};
