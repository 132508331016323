import { CardElement, useStripe, useElements, AddressElement, PaymentElement, LinkAuthenticationElement } from '@stripe/react-stripe-js';
import { useState } from 'react';

const CheckoutForm = ({onHandle, clientSecret, userData}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    elements.submit()

    const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        clientSecret,
        redirect:"if_required"
    });

    if (error) {
      console.error(error);
    } else if (paymentIntent.status === 'succeeded') {
      // Payment succeeded
      console.log('paymentIntent', paymentIntent);
      onHandle(paymentIntent.id)
    } else if (paymentIntent.status === 'requires_action') {
      // 3D Secure authentication required
      const { error: actionError, paymentIntent: confirmedPaymentIntent } = await stripe.confirmPayment(clientSecret);

      if (actionError) {
        setMessage(actionError);
      } else {
        // Payment succeeded after 3D Secure authentication
        console.log('confirmedPaymentIntent', confirmedPaymentIntent);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <LinkAuthenticationElement id="link-authentication-element"
        // Access the email value like so:
        // onChange={(event) => {
        //  setEmail(event.value.email);
        // }}
        //
        // Prefill the email field like so:
        options={{defaultValues: {email: userData?.user?.email}}}
        />
        <AddressElement options={{
            autocomplete:{
              mode:'disabled'
            },
            mode: 'shipping',
            allowedCountries: ['IN'],
            defaultValues:{
                name:userData?.user?.name,
                address:{
                    line1: "",
                    line2: "",
                    city: "",
                    state: "MH",
                    postal_code: "",
                    country: "IN"
                }
            },
            fields: {
                phone: 'always'
            },
            validation: {
                phone: {
                    required: 'never',
                },
            },
            }} 
        />
      <PaymentElement 
        id="payment-element"
        options={{
            fields:{
                billingDetails:"auto"
            },
            defaultValues:{
                billingDetails:{
                    name:userData?.user?.name,
                    address:{
                        line1: "",
                        line2: "",
                        city: "",
                        state: "MH",
                        postal_code: "",
                        country: "IN"
                    }
                }
            },
        }}
      />
        <button  className="btn btn-default btn-block mt-4" disabled={isLoading || !stripe || !elements} id="submit">
            <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
            </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
    </form>
  );
};

export default CheckoutForm;