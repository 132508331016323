import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Footer, Header, Loading } from "../components";
import Config from "../values/Strings/Config";
import { connect } from "react-redux";
import { ActionCreators } from "../actions";
import { useNavigate, Link } from "react-router-dom";
import { useAlert } from "react-alert";
import objectLogo from "../assets/images/object-icon-logo.png";
import iIcon from "../assets/images/i-icon.svg";
import closeIcon from "../assets/images/close.svg";
import headerShapeRight from "../assets/images/header-shape-right.svg";
import headerShapeLeft from "../assets/images/header-shape-left.svg";
import { fixedHeader } from "../values/utils";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../components/checkout';

const stripePromise = loadStripe(Config.STRIPE_PUBLISHABLE_KEY);

const Subscription = ({
  userData,
  completeOrderDSPCH,
  completeOrderData,
  logoutDSPCH,
  planDSPCH,
  planData,
  getSubscriptionContentDSPCH,
  subscriptionContentData,
  subscriptionContentLoad,
}) => {
  const alert = useAlert();
  const completeOrderDataRef = useRef(completeOrderData);
  let navigate = useNavigate();
  const [ clientSecret, setClientSecret ] = useState();
  const [ togglePlan, setTogglePlan ] = useState(null);
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
    fixedHeader();
    getPaln();
    getSubscriptionContent();
  }, []);

  const getPaln = () => {
    planDSPCH();
  };

  const getSubscriptionContent = () => {
    getSubscriptionContentDSPCH({
      slug: "subscription",
    });
  };

  useEffect(() => {
    if (completeOrderData !== completeOrderDataRef.current) {
      if (completeOrderData?.success === true) {
        alert.success(
          <div style={{ textTransform: "initial" }}>
            {completeOrderData?.message}
          </div>
        );
        navigate("/subscription-success");
      } else {
        alert.success(
          <div style={{ textTransform: "initial" }}>
            {completeOrderData?.message}
          </div>
        );
      }
    }
  }, [completeOrderData, completeOrderDataRef]);

  const handlecompleteOrder = (res, plan_id) => {
    // navigate("/subscription-success");
    if (userData?.success === true) {
      completeOrderDSPCH({
        user_id: userData?.data?.user?.id,
        plan_id: plan_id,
        razorpay_payment_id: res?.razorpay_payment_id,
        razorpay_subscription_id: res?.razorpay_subscription_id,
        razorpay_signature: res?.razorpay_signature,
      });
    }
  };

  const handleSubscription = (res, planName, plan_id) => {
    if (res?.success === true && userData?.success === true) {
      var options = {
        key_id: Config.RAZOR_PAY_KEY_ID,
        subscription_id: res?.data?.id,
        name: "Object Magazine",
        description: `${planName} Subscription Plan`,
        image: objectLogo,
        handler: function (response) {
          handlecompleteOrder(response, plan_id);
        },
        prefill: {
          name: userData?.data?.user?.name,
          email: userData?.data?.user?.email,
          contact: userData?.data?.user?.phone,
        },
        // notes: {
        //   note_key_1: "Tea. Earl Grey. Hot",
        //   note_key_2: "Make it so.",
        // },
        theme: {
          color: "#000",
        },
      };
      let paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }
  };

  const buyNow = (plan_id, planName) => {
    if (userData?.success === true) {
      fetch(Config.RAZOR_PAY_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          plan_id: plan_id,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson?.success === true) {
            handleSubscription(responseJson, planName, plan_id);
          } else {
            navigate("/");
            logoutDSPCH();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      navigate("/sign-in");
    }
  };

  const _handleToken = (token, slug) => {
    
    if (userData?.success === true) {
      completeOrderDSPCH({
        token,
        plan_id: slug
      }, userData?.data?.token);
    }

  };

  const createSubscriptionforCharging = async (plan_id) => {
    setTogglePlan(plan_id)
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'createPaymentIntent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userData?.data?.token}`,
      },
      body: JSON.stringify({
        plan_id, // Replace with the desired amount in cents
      }),
    });

    const { success, message, data } = await response.json();

    if(!success) {
      return alert.error(
        <div style={{ textTransform: "initial" }}>
          {message}
        </div>
      );
    }

    if(data?.client_secret) {
      setClientSecret(data?.client_secret)
    }

  }

  const renderBuyButton = (item) => {

    if(!userData.success && !item?.sold_out){
      return <button
              onClick={() => navigate("/sign-in")}
              className="btn btn-default btn-block mt-3"
            >
              PRE ORDER
            </button>
    }

    if(userData.success && item.subscription && item.subscription[0]?.status=="succeeded"){
      return <button
              disabled
              className="btn btn-default btn-block  mt-3"
            >
             Subscribed
            </button>
    }
    else if(item?.sold_out) {
      return <button
              disabled
              className="btn btn-default btn-block  mt-3"
            >
             SOLD OUT
            </button>
    }
    else if(userData.success) {
      return clientSecret && togglePlan === item.slug ? <Elements stripe={stripePromise} options={{ clientSecret }} >
      <CheckoutForm clientSecret={clientSecret} onHandle={(token) => _handleToken(token, item.slug)} planId={item.slug} userData={userData?.data} />
    </Elements> : <button onClick={() => createSubscriptionforCharging(item.slug)} className="btn btn-default btn-block  mt-3">
      PRE ORDER
    </button>
    }

  }

  return (
    <div>
      <Helmet>
        <title>Subscription Plans - Object Magazine</title>
        <meta name="description" content="{}" />
      </Helmet>

      <Header />

      {/* <!--============================== Main Start ==============================--> */}
      <main id="main">
        {/* <!--============================== Content Start ==============================--> */}
        {subscriptionContentLoad ? (
          <Loading type={"spin"} color={"#000"} />
        ) : (
          <div className="content-container less-pad pb-0">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-heading">
                    <h1>SUBSCRIPTION PLANS</h1>
                  </div>
                  <div
                    className="subscription-intro"
                    dangerouslySetInnerHTML={{
                      __html: subscriptionContentData?.data?.description,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* <!--============================== Content End ==============================--> */}
        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <ul className="subscription-list d-flex flex-wrap">
                  {planData?.success === true &&
                    planData?.data?.map((item, ind) => (
                      <li className="subscription-item" key={ind}>
                        <div className="subscription-box">
                          {item.image && <img src={item?.image} alt={item?.name} />}
                          
                          {/* <button className="btn btn-default btn-block ">
                            BUY NOW
                          </button> */}
                          {renderBuyButton(item)}
                          <a
                            href="#!"
                            data-bs-target={"#InfoModal" + ind}
                            data-bs-toggle="modal"
                            data-bs-placement="bottom"
                            className="subscription-i"
                          >
                            <img src={iIcon} alt="" />
                          </a>
                          <div
                            className="modal fade subsc-modal"
                            id={"InfoModal" + ind}
                            tabindex="-1"
                            aria-labelledby={"InfoModalLabel" + ind}
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content">
                                <div className="modal-body">
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <img src={closeIcon} alt="" />
                                  </button>
                                  <div className="student-plane">
                                    <h4>{item?.name} Plan</h4>
                                    <ul className="modal-text-list">
                                      <div
                                        className="post-single block-text"
                                        dangerouslySetInnerHTML={{
                                          __html: item?.description,
                                        }}
                                      />
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {item?.sold_out === 1 && (
                            <div className="recommend">
                              <span>SOLD OUT</span>
                            </div>
                          )}
                        </div>
                        <h3>{item?.name}</h3>
                          <h4>
                            Rs. {item?.amount} <sub> ONLY</sub>
                          </h4>
                          <p className="d-none">
                            at just Rs.
                            {parseFloat(item?.amount / 30).toFixed(2)}
                            /DAY
                          </p>
                      </li>
                    ))}
                </ul>
                <div className="cta-blog text-center mb-0">
                  <p>
                    The best of <span>Object Magazine,</span> in your inbox.
                    <Link to="/newsletter">
                      Sign up for our Newsletters now.
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--============================== Content Start ==============================--> */}
        <div className="content-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="end-section">
                  <h5>
                    <span className="left">
                      <img src={headerShapeRight} alt="" />
                    </span>
                    end
                    <span className="right">
                      <img src={headerShapeLeft} alt="" />
                    </span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============================== Content End ==============================--> */}
      </main>
      {/* <!--============================== Main End ==============================--> */}
      {/* <!--============================== Footer Start ==============================--> */}
      <Footer />
      {/* <!--============================== Footer End ==============================--> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userData: state.login.user,
  completeOrderData: state.completeOrder.data,
  completeOrderLoad: state.completeOrder.load,
  planData: state.plan.data,
  subscriptionContentData: state.cmsFooter.data,
  subscriptionContentLoad: state.cmsFooter.load,
});

const mapDispatchToProps = (dispatch) => ({
  completeOrderDSPCH: (data, token) => {
    dispatch(ActionCreators.completeOrder(data, token));
  },
  logoutDSPCH: () => {
    dispatch(ActionCreators.logoutUser());
  },
  planDSPCH: () => {
    dispatch(ActionCreators.plan());
  },
  getSubscriptionContentDSPCH: (data) => {
    dispatch(ActionCreators.cmsFooter(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
