const BASE_URL = process.env.REACT_APP_BASE_URL;
const IMG_BASE_URL = process.env.REACT_APP_IMG_BASE_URL;

export default class Config {
  static IMG_URL = IMG_BASE_URL + "post/";
  static AUTHOR_IMG_URL = IMG_BASE_URL + "authors/";
  static SUBJECT_IMG_URL = IMG_BASE_URL + "subject/";
  static TRACKERDATA_IMG_URL = IMG_BASE_URL + "homepage/";
  static CMS_IMG_URL = IMG_BASE_URL + "pages/";
  static USER_IMG_URL = IMG_BASE_URL + "users/";

  static GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  static RAZOR_PAY_KEY_ID = process.env.REACT_APP_RAZOR_PAY_KEY_ID;
  static STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

  static LOGIN_URL = BASE_URL + "login";
  static REGISTER_URL = BASE_URL + "register";
  static HOME_PAGE_URL = BASE_URL + "pages/homepage";
  static GET_ARTICLE_URL = BASE_URL + "posts/getArticlesBySlug/";
  static AUTHOR_URL = BASE_URL + "authors/getAll";
  static SUBJECT_URL = BASE_URL + "subjects/getAll";
  static PROFILE_URL = BASE_URL + "profile/getUserDetails";
  static INDIVIDUAL_SUBJECT_URL =
    BASE_URL + "subjects/getSubjectDetailsBySlug/";
  static INDIVIDUAL_AUTHOR_URL = BASE_URL + "authors/getAuthorDetailsBySlug/";
  static RAZOR_PAY_URL = BASE_URL + "razorpayment/createSubscription";
  static STRIPE_PAY_URL = BASE_URL + "createStripeSubscription";
  static GOOGLE_SIGNIN_URL = BASE_URL + "loginByGoogle";
  static COMPLETE_ORDER_URL = BASE_URL + "razorpayment/verifySignature";
  static NEWSLETTER_SUBSCRIPTION_URL = BASE_URL + "newsletter/post";
  static PLAN_URL = BASE_URL + "plans/getAll";
  static PROFILE_UPDATE_URL = BASE_URL + "profile/updateProfile";
  static SEARCH_ARTICLE_URL = BASE_URL + "posts/searchArticles";
  static FORGET_PASSWORD_URL = BASE_URL + "forgetPassword";
  static RESET_PASSWORD_URL = BASE_URL + "resetPassword";
  static ABOUT_US_URL = BASE_URL + "page/getContent/about-us";
  static CMS_FOOTER_URL = BASE_URL + "page/getContent/";
  static SAVE_ARTICLE_URL = BASE_URL + "posts/saveUserArticle";
  static GET_CROSSWORD_URL = BASE_URL + "crossword/getAll";
  static GET_CROSSWORD_SINGLE_URL = BASE_URL + "crossword/getContent/";
  static GET_SAVED_ARTICLE_URL = BASE_URL + "posts/getUserSavedArticle";
  static GET_ARCHIVE_YEAR_URL = BASE_URL + "getArchiveYears";
  static GET_ARCHIVE_DATA_URL = BASE_URL + "getArchiveData";
  static GET_FAQ_URL = BASE_URL + "faq/getAll";
  static REMOVE_SAVEDARTICLE_URL = BASE_URL + "posts/removeUserArticle";
  static CANCEL_SUBSCRIPTION_URL = BASE_URL + "cancelStripeSubscription";
  static GET_ALL_VIDEO_ARTICLE = BASE_URL + "posts/getAllVideoArticle";
}
