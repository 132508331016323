import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";

import {
  Home,
  Article,
  EditorNote,
  AboutUs,
  Contact,
  Privacy,
  TermCondition,
  RefundCancellation,
  DeliveryShipping,
  Search,
  PodcastVideo,
  Author,
  Subscription,
  DailyCrossword,
  Profile,
  Subject,
  Login,
  Register,
  ForgotPassword,
  NewsLetter,
  SubscriptionThankyou,
  ProfileEdit,
  NewsLetterArtsAndCulture,
  NewsLetterEnvironment,
  NewsLetterSports,
  SubscriptionInActive,
  SubscriptionActive,
  PageNotFound,
  ResetPassword,
  CrosswordSingle,
  Archive,
  FAQ
} from "./pages";

class Navigation extends React.Component {
  render() {
    return (
      <BrowserRouter basename="/">
         {/* <BrowserRouter>        */}
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/article/:id" element={<Article />} />
            <Route path="/issue-dispatch" element={<EditorNote />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/terms-and-conditions" element={<TermCondition />} />
            <Route
              path="/refund-and-cancellations"
              element={<RefundCancellation />}
            />
            <Route
              path="/delivery-and-shipping"
              element={<DeliveryShipping />}
            />
            <Route path="/search" element={<Search />} />
            <Route path="/videos-podcasts" element={<PodcastVideo />} />
            <Route path="/author/:id" element={<Author />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/daily-crossword" element={<DailyCrossword />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/subject/:id" element={<Subject />} />
            <Route path="/sign-in" element={<Login />} />
            <Route path="/sign-up" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/newsletter" element={<NewsLetter />} />
            <Route path="/subscription-success" element={<SubscriptionThankyou />} />
            <Route path="/profile-edit" element={<ProfileEdit />} />
            <Route path="/newsletter-arts-and-culture" element={<NewsLetterArtsAndCulture />} />
            <Route path="/newsletter-environment" element={<NewsLetterEnvironment />} />
            <Route path="/newsletter-sports" element={<NewsLetterSports />} />
            <Route path="/subscription-inactive" element={<SubscriptionInActive />} />
            <Route path="/subscription-active" element={<SubscriptionActive />} />
            <Route path="*" element={<PageNotFound/>} />
            <Route path="/reset-password/:token" element={<ResetPassword/>} />
            <Route path="/crossword/:slug" element={<CrosswordSingle/>} />
            <Route path="/archives" element={<Archive />} />
            <Route path="/faq" element={<FAQ />} />
          </Routes>
        </main>
      </BrowserRouter>
    );
  }
}

export default Navigation;
